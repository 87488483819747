// Generated by Framer (b12dec4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["I21:958;32:635"];

const variantClassNames = {"I21:958;32:635": "framer-v-1k59au8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, CRRZhvq2Q: link ?? props.CRRZhvq2Q, sBsofeeLk: title ?? props.sBsofeeLk ?? "Home"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, CRRZhvq2Q, sBsofeeLk, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "I21:958;32:635", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-fRAqy", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={CRRZhvq2Q}><motion.a {...restProps} className={`${cx("framer-1k59au8", className)} framer-16frp6x`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"I21:958;32:635"} ref={ref} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXIgVGlnaHQtNjAw", "--framer-font-family": "\"Inter Tight\", sans-serif", "--framer-font-weight": "600", "--framer-line-height": "24px", "--framer-text-color": "var(--extracted-r6o4lv)"}}>SquareDocs</motion.p></React.Fragment>} className={"framer-1fyb1fv"} data-framer-name={"SquareDocs"} fonts={["GF;Inter Tight-600"]} layoutDependency={layoutDependency} layoutId={"I21:958;21:950"} style={{"--extracted-r6o4lv": "var(--token-2a680c9a-4e39-4faf-a979-408e1697909f, rgb(29, 30, 32))", "--framer-paragraph-spacing": "0px"}} text={sBsofeeLk} verticalAlignment={"top"} withExternalLayout/><Image background={{alt: "", fit: "fill", intrinsicHeight: 350, intrinsicWidth: 370, pixelHeight: 350, pixelWidth: 370, src: new URL("assets/Xdnu4QaN9PRBtl86K2Ljg1oZAII.png", import.meta.url).href}} className={"framer-4psezk"} data-framer-name={"Screenshot_2023_09_13_at_4_23_25_PM"} layoutDependency={layoutDependency} layoutId={"iZOt5RKmK"}/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-fRAqy [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-fRAqy .framer-16frp6x { display: block; }", ".framer-fRAqy .framer-1k59au8 { height: 24px; overflow: visible; position: relative; text-decoration: none; width: 125px; }", ".framer-fRAqy .framer-1fyb1fv { flex: none; height: auto; left: 36px; position: absolute; top: 0px; white-space: pre; width: auto; }", ".framer-fRAqy .framer-4psezk { aspect-ratio: 1.0571428571428572 / 1; bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; top: 0px; width: var(--framer-aspect-ratio-supported, 25px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 125
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"CRRZhvq2Q":"link","sBsofeeLk":"title"}
 * @framerImmutableVariables false
 */
const FramerEUkzXtG5j: React.ComponentType<Props> = withCSS(Component, css, "framer-fRAqy") as typeof Component;
export default FramerEUkzXtG5j;

FramerEUkzXtG5j.displayName = "logo";

FramerEUkzXtG5j.defaultProps = {height: 24, width: 125};

addPropertyControls(FramerEUkzXtG5j, {CRRZhvq2Q: {title: "Link", type: ControlType.Link}, sBsofeeLk: {defaultValue: "Home", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerEUkzXtG5j, [{family: "Inter Tight", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/EUkzXtG5j:default", url: "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mj0QiqXCRToK8EPg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mj0QiqXCRToK8EPg.ttf", weight: "600"}])